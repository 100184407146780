@import 'normalize.css/normalize.css';
@import 'mapbox-gl/dist/mapbox-gl.css';

html,
body,
#map {
    width: 100%;
    height: 100%;
}

body {
    font-size: 16px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#statistics {
    position: fixed;
    top: 50px;
    left: 50px;
    background: #ffffff;
    padding: 2px;
    border-radius: 3px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    width: 250px;
}

.statistics-inner {
    border: 1px dashed #d5d9e5;
    color: #282828;
    padding: 20px;
}

h1 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
}

p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.5rem;
}

.is-hidden {
    display: none;
}
